import React from "react";
import { Link } from "react-router-dom";
import reliableLongLogo from "../../../../assets/png/reliable_long_logo.png";
import Divider from "../../../../components/utils/divider";
import VerticalSpacer from "../../../../components/utils/vertical_spacer";

import "./footer.css";

export default function Footer() {
  const serviceData = [
    [
      { label: "ERP Management System", to: "/erp" },
      { label: "HR Management System", to: "/hr" },
      { label: "School Management System", to: "/school" },
      { label: "Clinic Management System", to: "/clinic" },
    ],
    [
      { label: "Terms & Conditions", to: "https://www.google.com" },
      { label: "Privacy Policy", to: "https://www.google.com" },
    ],
    [
      { label: "Custom Development", to: "" },
      { label: "Mobile App Development", to: "" },
    ],
    [
      { label: "Instagram", to: "https://www.instagram.com/reliablesuite/" },
      {
        label: "LinkedIn",
        to: "https://www.linkedin.com/company/reliable-suite/",
      },
      { label: "Facebook", to: "https://www.facebook.com/ReliableSuite" },
    ],
  ];
  const serviceTitles = [
    "Our solutions",
    "Trust & Legal",
    "Services",
    "Socials",
  ];
  return (
    <>
      <div className="footer-root">
        <div className="footer-container">
          <div className="footer-service-grid">
            <ServiceColumn
              key={serviceTitles[0]}
              title={serviceTitles[0]}
              options={serviceData[0]}
            />
            <ServiceColumn
              key={serviceTitles[1]}
              title={serviceTitles[1]}
              options={serviceData[1]}
              isExternalNavigation={true}
            />
            <ServiceColumn
              key={serviceTitles[2]}
              title={serviceTitles[2]}
              options={serviceData[2]}
            />
            <ServiceColumn
              key={serviceTitles[3]}
              title={serviceTitles[3]}
              options={serviceData[3]}
              isExternalNavigation={true}
            />
          </div>
          <VerticalSpacer height={"72px"} />
          <div className="footer-data-grid">
            <Link to={"/"}>
              <img
                src={reliableLongLogo}
                style={{ width: "80%" }}
                alt="reliableLogo"
              />
            </Link>
            <ContactColumn
              title={"Contact Us"}
              description={"00965 99374176"}
              href={"tel:00965 99374176"}
            />
            <ContactColumn
              title={"Email"}
              description={"info@reliablesol.com"}
              href={"mailto:info@reliablesol.com"}
            />
            <ContactColumn
              title={"Address"}
              description={
                "Shayma Tower, Murgab, Block 3, Plot 8 A & B, 12th Floor, Office No 2, Kuwait City, Kuwait"
              }
              href={
                "http://maps.google.com/?q=Shayma+Tower,+Omar+Ben+Al+Khattab+St,+Al+Kuwayt,+Kuwait"
              }
            />
          </div>
          <VerticalSpacer height={"24px"} />
          <Divider height={"1px"} color={"#c4c4c4"} />
          <VerticalSpacer height={"24px"} />
          <div className="footer-copyright">
            <a
              href={"https://logicraftsolutions.com/"}
              target="_blank"
              className="footer-created-by"
              rel="noreferrer"
            >
              {"Created by"}
              <div className="footer-created-logo">Logicraft Solutions</div>
            </a>
            <div className="footer-copyright-text">
              Copyright {new Date().getFullYear()} Reliable Solutions
            </div>
          </div>
        </div>
        <VerticalSpacer height={"72px"} />
        <div className="footer-arc">
          RELIABLE
          <div style={{ fontWeight: "200", padding: "0 6px" }}>
            ENTREPRENEUR
          </div>
        </div>{" "}
      </div>
    </>
  );
}

function ServiceColumn({
  alignItems = "center",
  title,
  options,
  isExternalNavigation,
}) {
  return (
    <div className="footer-service-container">
      <div className="footer-service-header">{title}</div>
      <VerticalSpacer height={"12px"} />
      {options.map((e) => (
        <>
          {isExternalNavigation !== true ? (
            <Link key={e} to={e.to} className="footer-service-link">
              {e.label}
            </Link>
          ) : (
            <a
              key={e}
              target="_blank"
              href={e.to}
              className="footer-service-link"
              rel="noreferrer"
            >
              {e.label}
            </a>
          )}
          <VerticalSpacer height={"8px"} />
        </>
      ))}
    </div>
  );
}

function ContactColumn({ title, description, href }) {
  return (
    <div className="footer-contact-column">
      <div className="footer-contact-title">{title}</div>
      <a href={href} className="footer-contact-description">
        {description}
      </a>
    </div>
  );
}
